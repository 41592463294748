import React, { Component } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeadRow,
  TableData,
  RemoveEditAcessFile
} from '../utils/Table';
import { connect } from 'react-redux';
import { BodyTable } from '../utils/BodyTable';
import ComponentHeader from '../utils/ComponenteHeader';
import moment from 'moment-timezone';
import { browserHistory } from 'react-router';
import RepositorieApi from '../../Logicas/RepositoryApi';
import { DialogYesNo } from '../utils/DailogComponentes';
import DialogApi from '../../Logicas/DialogApi';
import ArrowBack from './Components/ArrowBack';
class FileListRepository extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { list } = this.props;
    if (!list.length) browserHistory.push('/files/folders');
  }

  render() {
    return (
      <div>
        <ComponentHeader
          actions={[]}
          name="Arquivos"
          nameaction="Adicionar arquivo +"
          onClick={function() {
            browserHistory.push('/file/create');
          }}
        />
        <div className="container">
          <div onClick={() => browserHistory.push('/files/folders')}>
            <ArrowBack />
          </div>
          <BodyTable>
            <Table>
              <TableHead>
                <TableHeadRow>
                  <label>Id{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Nome{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Formato{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Criado em{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Criado por{this.state.oderico}</label>
                </TableHeadRow>
                <TableHeadRow>
                  <label>Ações{this.state.oderico}</label>
                </TableHeadRow>
              </TableHead>
              <TableBody>
                {Array.isArray(this.props.list.files)
                  ? this.props.list.files.map(
                      function(file, i) {
                        return (
                          <TableRow value={i}>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>{file.id}</div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {file.name_file.length > 80
                                  ? `${file.name_file.slice(0, 80)}...`
                                  : file.name_file}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {file.format_file}
                              </div>
                            </TableData>
                            <TableData>
                              <div style={{ marginTop: '10px' }}>
                                {moment
                                  .tz(file.created_at, 'Europe/Berlin')
                                  .format('DD/MM/YYYY')}
                              </div>
                            </TableData>
                            <TableData>
                              {file.user !== undefined ? (
                                <div style={{ marginTop: '10px' }}>
                                  {file.user.name}
                                </div>
                              ) : (
                                undefined
                              )}
                            </TableData>
                            <TableData>
                              {file.file !== undefined ? (
                                file.file.url !== null ? (
                                  <div style={{ marginTop: '10px' }}>
                                    <RemoveEditAcessFile
                                      Delete={this.props.deleteShowDialogFile.bind(
                                        this,
                                        file.id
                                      )}
                                      Edit={this.props.Edit.bind(
                                        this,
                                        this.props.list.folder_id,
                                        file.id
                                      )}
                                      filelink={file.file.url}
                                    />
                                  </div>
                                ) : (
                                  <div style={{ marginTop: '10px' }}>
                                    <RemoveEditAcessFile
                                      Delete={this.props.deleteShowDialogFile.bind(
                                        this,
                                        file.id
                                      )}
                                      Edit={this.props.Edit.bind(
                                        this,
                                        this.props.list.folder_id,
                                        file.id
                                      )}
                                      filelink={file.link}
                                    />
                                  </div>
                                )
                              ) : (
                                undefined
                              )}
                            </TableData>
                          </TableRow>
                        );
                      }.bind(this)
                    )
                  : undefined}
              </TableBody>
            </Table>
          </BodyTable>
        </div>

        <DialogYesNo
          yesclick={this.props.Delete.bind(
            this,
            this.props.list.folder_id,
            this.props.alert.id_elemente
          )}
          noclick={this.props.closeDialog}
          onClick={this.props.closeDialog}
          visible={this.props.alert.visible}
          message="Tem certeza que deseja remover este arquivo ?"
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    list: state.repositories,
    alert: state.dialog,
    pagination: state.pagination
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    Edit: (values, idfile, id) => {
      dispatch(RepositorieApi.getEditFiles(values, idfile));
    },
    Delete: (folder, file) => {
      dispatch(RepositorieApi.deleteFile(folder, file));
    },
    deleteShowDialogFile: (values, id) => {
      dispatch(DialogApi.deleteAlert(values));
    },
    closeDialog: () => {
      dispatch(DialogApi.closeDialog());
    }
  };
};
const FoldersListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileListRepository);

export default FoldersListContainer;
