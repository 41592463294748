
  export const style={
    inpuStyle:{
      colorInput:"#DF9B00"
    },
    url:{
      urlClient:"https://vedacit.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/logo_client_vedacit.png"
    },
    CurrencyName:{
      name:"Baldinho/Baldinhos"
    },
    teamName:{
      name:"Vedacit"
    },
    hasToken: false,
    hostHeader: "vedacit"
  }
